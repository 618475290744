<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧组件
    CustomerList: () => import('./left/CustomerList.vue'),

    // 底部组件
    IndexTable: () => import('./bottom/IndexTable.vue'),
    Detail: () => import('./bottom/Detail.vue')
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      // this.setLeftComponent('CustomerList')
      this.setBottomComponent('IndexTable')
    }
  },
  destroyed () {
    // 卸载installSetting动态store模块
    // this.$store.commit('reset_state')
    // this.$store.unregisterModule('installSetting')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
